import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import styles from "./ImageUseesPlaceholderPopup.module.css";

const ImageUsesPlaceholderPopup = (props) => {
  const [imageUseOptions, setImageUseOptions] = useState({
    type: "image_use_list",
    show_original_image: false,
    original_image_label: "",
    show_original_image_name: false,
    original_image_name_label: "",
    show_byline: false,
    byline_label: "",
    show_license_fee: false,
    license_fee_label: "",
    show_found_image: false,
    found_image_label: "",
    show_page_link: false,
    page_link_label: "",
    created: null,
    updated: "",
  });
  const oldImageUseOptions = props.oldImageUseOptions;

  useEffect(() => {
    if (oldImageUseOptions) setImageUseOptions(oldImageUseOptions);
  }, [oldImageUseOptions]);

  const handleInputChange = (event) => {
    const { type, name } = event.target;
    const value =
      type === "checkbox" ? event.target.checked : event.target.value;
    setImageUseOptions((prevImageUseOptions) => ({
      ...prevImageUseOptions,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const nowDate = new Date();
    setImageUseOptions((prevImageUseOptions) => ({
      ...prevImageUseOptions,
      updated: nowDate,
      ...(prevImageUseOptions.created && { created: nowDate }),
    }));
    props.onAdd("image_uses", imageUseOptions);
    handleClose();
  };
  const handleClose = () => {
    setImageUseOptions({
      type: "image_use_list",
      show_original_image: false,
      original_image_label: "",
      show_original_image_name: false,
      original_image_name_label: "",
      show_byline: false,
      byline_label: "",
      show_license_fee: false,
      license_fee_label: "",
      show_found_image: false,
      found_image_label: "",
      show_page_link: false,
      page_link_label: "",
      created: "",
      updated: "",
    });
    props.close();
  };
  return (
    <DialogPopup
      title="CONFIGURE IMAGE USES PLACEHOLDER"
      open={props.open}
      onClose={handleClose}
    >
      <div className={styles.popup_body}>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_original_image"
                  checked={imageUseOptions.show_original_image}
                  onChange={handleInputChange}
                />
              }
              label="Show original image"
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="original_image_label"
              label="Original image label"
              value={imageUseOptions.original_image_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_original_image_name"
                  checked={imageUseOptions.show_original_image_name}
                  onChange={handleInputChange}
                />
              }
              label="Show original image name"
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="original_image_name_label"
              label="Original image name label"
              value={imageUseOptions.original_image_name_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_byline"
                  value={imageUseOptions.show_byline}
                  onChange={handleInputChange}
                />
              }
              label="Show IPTC by-line"
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="byline_label"
              label="Byline label"
              value={imageUseOptions.byline_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_license_fee"
                  value={imageUseOptions.show_license_fee}
                  onChange={handleInputChange}
                />
              }
              label="Show license fee"
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="license_fee_label"
              label="Licesne fee label"
              value={imageUseOptions.license_fee_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_found_image"
                  value={imageUseOptions.show_found_image}
                  onChange={handleInputChange}
                />
              }
              label="Show found image screenshot"
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="found_image_label"
              label="Found image label"
              value={imageUseOptions.found_image_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_page_link"
                  value={imageUseOptions.show_page_link}
                  onChange={handleInputChange}
                />
              }
              label="Show page link"
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="page_link_label"
              label="Page link label"
              value={imageUseOptions.page_link_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="action_container">
        <Button className="btn_cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn_confirm" onClick={handleSubmit}>
          Add placeholder
        </Button>
      </div>
    </DialogPopup>
  );
};

export default ImageUsesPlaceholderPopup;
