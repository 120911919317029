import { emailConstants } from "../@_constants/email_flows.constants";

const initialState = {
  emailFlows: [],
  loading: false,
  message: "",
  selected_email: null,
  status: {
    EMAIL_FLOW_GET: "",

    EMAIL_FLOW_POST: "",

    EMAIL_FLOW_PUT: "",

    EMAIL_FLOW_DELETE: "",

    EMAIL_FLOW_SELECT: "",
  },
};

export function emailFlowReducer(state = initialState, action) {
  switch (action.type) {
    // EMAIL_CLEAR_STATUS_MESSAGE
    case emailConstants.EMAIL_FLOW_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // EMAIL GET REQUEST
    case emailConstants.EMAIL_FLOW_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, EMAIL_FLOW_GET: "request" },
      };
      break;
    case emailConstants.EMAIL_FLOW_GET_SUCCESS:
      state = {
        ...state,
        emailFlows: action.data,
        loading: false,
        message: "",
        status: { ...state.status, EMAIL_FLOW_GET: "success" },
      };
      break;
    case emailConstants.EMAIL_FLOW_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, EMAIL_FLOW_GET: action.error },
      };
      break;

    // EMAIL POST REQUEST
    case emailConstants.EMAIL_FLOW_POST_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, EMAIL_FLOW_POST: "request" },
      };
      break;
    case emailConstants.EMAIL_FLOW_POST_SUCCESS:
      state = {
        ...state,
        loading: false,
        emailFlows: [...state.emailFlows, action.data],
        message: "",
        status: { ...state.status, EMAIL_FLOW_POST: "success" },
      };

      break;
    case emailConstants.EMAIL_FLOW_POST_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, EMAIL_FLOW_POST: action.error },
      };
      break;

    // EMAIL PUT REQUEST
    case emailConstants.EMAIL_FLOW_UPDATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, EMAIL_FLOW_PUT: "request" },
      };
      break;
    case emailConstants.EMAIL_FLOW_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        emailFlows: action.data,
        message: "",
        status: { ...state.status, EMAIL_FLOW_PUT: "success" },
      };

      break;
    case emailConstants.EMAIL_FLOW_UPDATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, EMAIL_FLOW_PUT: action.error },
      };
      break;

    // EMAIL DELETE REQUEST
    case emailConstants.EMAIL_FLOW_DELETE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, EMAIL_FLOW_DELETE: "request" },
      };
      break;
    case emailConstants.EMAIL_FLOW_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        emailFlows: "",
        message: "",
        status: { ...state.status, EMAIL_FLOW_DELETE: "success" },
      };

      break;
    case emailConstants.EMAIL_FLOW_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, EMAIL_FLOW_DELETE: action.error },
      };
      break;

    // selected email
    case emailConstants.SELECT_EMAIL_FLOW:
      state = {
        ...state,
        selected_email: action.data,
        message: "",
        status: { ...state.status, EMAIL_FLOW_SELECT: "success" },
      };
      break;

    case emailConstants.ADD_FLOW_CONFIG:
      state = {
        ...state,
        emailFlows: state.emailFlows.map((flow) => {
          if (flow.id === action.id) {
            return {
              ...flow,
              config: action.config,
            };
          } else return flow;
        }),
      };
      break;

    default:
      break;
  }
  return state;
}
