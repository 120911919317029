import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./EmailTemplate.module.css";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import Button from "@material-ui/core/Button";
import { templateActions } from "@_actions/template.actions";
import { catchError, newLinesToPTag } from "@_utils/helpers";
import { emailActions } from "@_actions/email_flows.actions";
import { isNull } from "@_utils/helpers";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import AreYouSurePopup from "@_components/popups/AreYouSurePopup/AreYouSurePopup";
import { emailConstants } from "@_constants/email_flows.constants";
import { caseConstants } from "@_constants/case.constants";
import ChangeEmailTemplatePopup from "@_components/popups/ChangeEmailTemplatePopup/ChangeEmailTemplatePopup";
import InfoPopup from "@_components/popups/InfoPopup/InfoPopup";
import SalutationsPlaceholderPopup from "@_components/popups/SalutationsPlaceholderPopup/SalutationsPlaceholderPopup";
import ImageUsesPlaceholderPopup from "@_components/popups/ImageUsesPlaceholderPopup/ImageUsesPlaceholderPopup";
import $ from "jquery";
import "../../@_utils/highlightTextArea/jquery.highlighttextarea";

function EmailTemplate({ onClickOutside, data }) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const editor = useRef(null);

  const emailFlowReducer = useSelector((state) => state.emailFlowReducer);

  const templateReducer = useSelector((state) => state.templateReducer);
  const selectedTemplate = templateReducer.selected_template;
  const [templateDetails, setTemplateDetails] = useState({
    name: (selectedTemplate && selectedTemplate.name) || "",
    subject: (selectedTemplate && selectedTemplate.subject) || "",
    content: (selectedTemplate && selectedTemplate.content) || "",
    schedule_num_days:
      (selectedTemplate && selectedTemplate.schedule_num_days) || "",
    type: (selectedTemplate && selectedTemplate.type) || "",
    trigger_event:
      (selectedTemplate && selectedTemplate.trigger_event) || undefined,
    config: (selectedTemplate && selectedTemplate.config) || {},
  });

  const [node, setNode] = useState(null);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  // layout effect for setting height on resize event of the refenced node
  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        if (editor.current && editor.current.setOptions)
          editor.current.setOptions({
            ...editor.current.getContext().options,
            height:
              (
                document.getElementById("placeholder_container_id")
                  .clientHeight - 95
              ).toString() + "px",
          });
      };

      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [node]);

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.content)
      setContent(selectedTemplate.content);
    // update editor
    if (editor.current) {
      // set initial heigth. We have to use a timout here because our refernce div will sized only after some time
      setTimeout(() => {
        // use setOptions to update height
        editor.current.setOptions({
          ...editor.current.getContext().options,
          height:
            // get infor container height and calculate the new height
            (
              document.getElementById("placeholder_container_id").clientHeight -
              95
            ).toString() + "px",
        });
      }, 500);

      editor.current.setContents("");
      if (
        selectedTemplate &&
        selectedTemplate.content &&
        selectedTemplate.content.length > 0
      ) {
        editor.current.insertHtmlNoNewLine(
          newLinesToPTag(selectedTemplate.content),
          true
        );
      }
    }
  }, [selectedTemplate]);

  const [curPosition, setCurPosition] = useState();
  /**
   * Holds HTML of editor
   */
  const [content, setContent] = useState(
    (selectedTemplate && selectedTemplate.content) || ""
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [btnPressed, setBtnPressed] = useState(false);
  const [subjectClicked, setSubjectClicked] = useState(false);
  const [contentClicked, setContentClicked] = useState(false);
  const [disabledPlaceholders, setDisabledPlaceholders] = useState(true);
  const [placeHolderPresent, setPlaceHolderPresent] = useState("");
  const [placeHolderNotPresent, setPlaceHolderNotPresent] = useState("");
  const [openAreYouSurePopup, setOpenAreYouSurePopup] = useState(false);
  const [openPlaceholderAlternativePopup, setOpenPlaceholderAlternativePopup] =
    useState(false);
  const [openChangeEmailTemplatePopup, setOpenChangeEmailTemplatePopup] =
    useState(false);
  const [deleteOrSubmit, setDeleteOrSubmit] = useState(null);
  const [currentEmaiFlow, setCurrentEmailFlow] = useState({});
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [openImageUsesPopup, setOpenImageUsesPopup] = useState(false);
  const [oldImageUseOptions, setOldImageUseOptions] = useState(null);
  const [openSalutationsPopup, setOpenSalutationsPopup] = useState(false);
  const [oldSalutationsOptions, setOldSalutationsOptions] = useState(null);
  const [wordClicked, setWordClicked] = useState(null);

  const temlateContainerRef = useRef(null);

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.emailFlowId)
      setCurrentEmailFlow(
        emailFlowReducer.emailFlows.find(
          (email) => email.id === selectedTemplate.emailFlowId
        )
      );
  }, [emailFlowReducer, selectedTemplate]);

  useEffect(() => {
    const handleResize = () => {
      if (temlateContainerRef.current) {
      }
    };
    // Initial resize
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [temlateContainerRef]);

  useEffect(() => {
    dispatch(templateActions.getPlaceholders());
  }, [dispatch]);

  useEffect(() => {
    setBtnPressed(false);
    setContent((selectedTemplate && selectedTemplate.content) || "");
    setTemplateDetails({
      name: (selectedTemplate && selectedTemplate.name) || "",
      subject: (selectedTemplate && selectedTemplate.subject) || "",
      content: (selectedTemplate && selectedTemplate.content) || "",
      schedule_num_days:
        (selectedTemplate && selectedTemplate.schedule_num_days) || "",
      type: (selectedTemplate && selectedTemplate.type) || "",
      trigger_event:
        (selectedTemplate && selectedTemplate.trigger_event) || undefined,
      config: (selectedTemplate && selectedTemplate.config) || {},
    });
    setWordClicked(null);
    setOldImageUseOptions(null);
    setOldSalutationsOptions(null);
    // if(selectedTemplate.config) {
    //   setOldImageUseOptions(selectedTemplate.config.)
    // }
    $("textarea").highlightTextarea("destroy");
  }, [selectedTemplate]);

  const handleSaveCursorPosition = (e) => {
    setCurPosition(e.target.selectionStart);
    setSubjectClicked(false);
    setDisabledPlaceholders(false);
    setContentClicked(true);
  };

  useEffect(() => {
    setTemplateDetails({ ...templateDetails, content: content });

    let wordsArray = ["{{image_uses}}", "{{salutation}}"];
    for (let i = 0; i < 10; i++) {
      wordsArray.push("{{image_uses_" + i + "}}");
      wordsArray.push("{{salutation_" + i + "}}");
    }

    $("textarea").highlightTextarea({
      words: wordsArray,
      color: "#b3b3b3",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  // useEffect(() => {
  //   $("textarea").highlightTextarea("destroy");
  // }, [selectedTemplate]);

  const handleSubjectPlaceholder = (e) => {
    setCurPosition(e.target.selectionStart);
    setSubjectClicked(true);
    setDisabledPlaceholders(false);
    setContentClicked(false);
  };

  const handleInputChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    setCurPosition(e.target.selectionStart);
    if (name === "schedule_num_days" && value) {
      value = parseInt(value);
    }
    setTemplateDetails((templateDetails) => ({
      ...templateDetails,
      [name]: value,
    }));
  };

  const handleDeleteTemplate = () => {
    setDeleteOrSubmit(0);
    selectedTemplate.scheduled_emails &&
    selectedTemplate.scheduled_emails.some(
      (email) => email.status !== caseConstants.CASE_ACTIVITY_STATE_SENT
    )
      ? setOpenChangeEmailTemplatePopup(true)
      : setOpenAreYouSurePopup(true);
  };

  const deleteTemplate = (updateScheduledEmails) => {
    dispatch(
      templateActions.deleteTemplate(
        emailFlowReducer.selected_email,
        selectedTemplate.id,
        updateScheduledEmails
      )
    );
    setDeleteOrSubmit(null);
    setOpenChangeEmailTemplatePopup(false);
  };

  const checkScheduledDays = (num) => {
    if (
      templateReducer.flow_templates &&
      templateReducer.flow_templates[emailFlowReducer.selected_email]?.length >
        0
    ) {
      let template = templateReducer.flow_templates[
        emailFlowReducer.selected_email
      ].find(
        (x) =>
          selectedTemplate &&
          x &&
          x.type === emailConstants.TEMPLATE_TYPE_SCHEDULED &&
          x.name !== selectedTemplate.name &&
          x.schedule_num_days === num
      );
      return template;
    }
    return false;
  };

  const validateInputs = () => {
    if (isNull(templateDetails.name)) {
      setErrorMessage("Please enter a name for your template.");
      setOpenErrorSnackbar(true);
      return false;
    } else if (isNull(templateDetails.subject)) {
      setErrorMessage("Please enter a subject for your template.");
      setOpenErrorSnackbar(true);
      return false;
    } else if (isNull(templateDetails.content)) {
      setErrorMessage("Please enter content for your template.");
      setOpenErrorSnackbar(true);
      return false;
    } else if (
      isNull(templateDetails.schedule_num_days) &&
      !(
        selectedTemplate.type === emailConstants.TEMPLATE_TYPE_TRIGGERED ||
        selectedTemplate.type === emailConstants.TEMPLATE_TYPE_CUSTOM
      )
    ) {
      setErrorMessage("Please enter Number of Days for your template.");
      setOpenErrorSnackbar(true);
      return false;
    }
    return true;
  };

  const handleScheduleNumDays = () => {
    if (
      selectedTemplate.type === emailConstants.TEMPLATE_TYPE_TRIGGERED ||
      selectedTemplate.type === emailConstants.TEMPLATE_TYPE_CUSTOM
    ) {
      delete templateDetails.schedule_num_days;
    } else if (
      selectedTemplate.type === emailConstants.TEMPLATE_TYPE_SCHEDULED &&
      !selectedTemplate.trigger_event
    ) {
      const templateWithSameDays = checkScheduledDays(
        templateDetails.schedule_num_days
      );
      if (templateWithSameDays) {
        setErrorMessage(
          `Another email is scheduled to be sent after ${templateDetails.schedule_num_days} day(s), please choose another period.`
        );
        setOpenErrorSnackbar(true);
        return false;
      }
    }
    return true;
  };

  const handleSubmitTemplateDetails = (updateScheduledEmails) => {
    setBtnPressed(true);

    if (!handleScheduleNumDays()) return;

    if (!validateInputs()) return;

    templateDetails["emailFlowId"] = emailFlowReducer.selected_email;

    // Checks if we are updating an existing template or creating a new one
    if (selectedTemplate && selectedTemplate.id) {
      // Checks if the template is a scheduled mail and if it is attached to a case and not sent
      if (
        selectedTemplate.scheduled_emails &&
        selectedTemplate.scheduled_emails.some(
          (email) => email.status !== caseConstants.CASE_ACTIVITY_STATE_SENT
        ) &&
        !(typeof updateScheduledEmails === "boolean")
      ) {
        setDeleteOrSubmit(1);
        setOpenChangeEmailTemplatePopup(true);
      } else {
        dispatch(
          templateActions.updateTemplate(
            emailFlowReducer.selected_email,
            selectedTemplate.id,
            updateScheduledEmails,
            templateDetails
          )
        );
        setDeleteOrSubmit(null);
        setOpenChangeEmailTemplatePopup(false);
      }
    } else if (templateDetails && templateReducer.add_template) {
      //Checks if the template is of type scheduled mail and the flow is attached to an active case
      if (
        templateDetails.type === emailConstants.TEMPLATE_TYPE_SCHEDULED &&
        currentEmaiFlow.cases?.some(
          (c) => c.state === caseConstants.CASE_STATE_ACTIVE
        ) &&
        !(typeof updateScheduledEmails === "boolean")
      ) {
        setDeleteOrSubmit(1);
        setOpenChangeEmailTemplatePopup(true);
      } else {
        dispatch(
          templateActions.addTemplate(
            emailFlowReducer.selected_email,
            updateScheduledEmails,
            templateDetails
          )
        );
        setDeleteOrSubmit(null);
        setOpenChangeEmailTemplatePopup(false);
      }
    }
    setBtnPressed(false);
  };

  useEffect(() => {
    if (
      templateReducer.status.TEMPLATE_POST === "success" ||
      templateReducer.status.TEMPLATE_PUT === "success"
    ) {
      dispatch(emailActions.getEmail());
      dispatch(templateActions.getTemplates(emailFlowReducer.selected_email));
      if (templateReducer.status.TEMPLATE_POST === "success")
        dispatch(templateActions.selectTemplate(templateDetails));
      dispatch(templateActions.clearStatusMessage("TEMPLATE_POST"));
      dispatch(templateActions.clearStatusMessage("TEMPLATE_PUT"));
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    templateReducer.status.TEMPLATE_POST,
    templateReducer.status.TEMPLATE_PUT,
    emailFlowReducer.selected_email,
  ]);

  useEffect(() => {
    if (
      templateReducer.flow_templates &&
      templateReducer.flow_templates[emailFlowReducer.selected_email]?.length >
        0
    ) {
      let template = templateReducer.flow_templates[
        emailFlowReducer.selected_email
      ].find((x) => selectedTemplate && x && x.name === selectedTemplate.name);
      if (template && !selectedTemplate.id) {
        dispatch(templateActions.selectTemplate(template));
      }
    }
  }, [
    dispatch,
    templateReducer.flow_templates,
    selectedTemplate,
    emailFlowReducer.selected_email,
  ]);

  // display template POST success & error
  useEffect(() => {
    if (templateReducer.status.TEMPLATE_POST === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      if (templateReducer.affectedCases.length > 0) setOpenInfoPopup(true);
      dispatch(templateActions.clearStatusMessage("TEMPLATE_POST"));
    } else if (catchError(templateReducer.status.TEMPLATE_POST)) {
      setErrorMessage(templateReducer.status.TEMPLATE_POST);
      setOpenErrorSnackbar(true);
      dispatch(templateActions.clearStatusMessage("TEMPLATE_POST"));
    } else return;
  }, [dispatch, templateReducer.status, templateReducer.affectedCases]);

  // display template PUT success & error
  useEffect(() => {
    if (templateReducer.status.TEMPLATE_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      if (templateReducer.affectedCases.length > 0) setOpenInfoPopup(true);

      dispatch(templateActions.clearStatusMessage("TEMPLATE_PUT"));
    } else if (catchError(templateReducer.status.TEMPLATE_PUT)) {
      setErrorMessage(templateReducer.status.TEMPLATE_PUT);
      setOpenErrorSnackbar(true);
      dispatch(templateActions.clearStatusMessage("TEMPLATE_PUT"));
    } else return;
  }, [dispatch, templateReducer.status, templateReducer.affectedCases]);

  const handleAddPlaceholder = (placeholder, subjectPlaceholder) => {
    if (subjectClicked && subjectPlaceholder) {
      setDisabledPlaceholders(false);
      if (curPosition || curPosition === 0) {
        let textBeforeCursorPosition = templateDetails.subject.substring(
          0,
          curPosition
        );
        let textAfterCursorPosition = templateDetails.subject.substring(
          curPosition,
          templateDetails.subject.length
        );
        setTemplateDetails({
          ...templateDetails,
          subject: `${textBeforeCursorPosition}{{${placeholder}}}${textAfterCursorPosition}`,
        });
      } else {
        setTemplateDetails({
          ...templateDetails,
          subject: `${templateDetails.subject}{{${placeholder}}}`,
        });
      }

      document.getElementById("subject_input").focus();
    } else if (contentClicked) {
      if (placeholder === "greeting") {
        openPlaceholderAlternative();
        return;
      }
      if (placeholder === "image_uses") {
        setOldImageUseOptions(null);
        setOpenImageUsesPopup(true);
        return;
      }
      if (placeholder === "salutation") {
        if (currentEmaiFlow.config?.salutation) {
          setOldSalutationsOptions(currentEmaiFlow.config?.salutation);
        } else {
          setOldSalutationsOptions(null);
        }
        setOpenSalutationsPopup(true);
        return;
      }
      setDisabledPlaceholders(false);
      addPlaceholderToContent("{{" + placeholder + "}}");
    } else return;
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorMessage("");
    setSuccessMessage("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      setDisabledPlaceholders(true);
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside, dispatch]);

  const openPlaceholderAlternative = () => {
    // reset fields
    setPlaceHolderNotPresent("To Whom It May Concern,");
    setPlaceHolderPresent("Dear {{name}},");
    // open
    setOpenPlaceholderAlternativePopup(true);
  };

  const writePlaceholderAlternativeToEditor = () => {
    /* if (editor.current === undefined) return;

    var placeholder = `{{#if name}}${placeHolderPresent}{{else}}${placeHolderNotPresent}{{/if}}`;
    editor.current.insertHTML(`${placeholder}`);
*/
    var placeholder = `{{#if name}}${placeHolderPresent}{{else}}${placeHolderNotPresent}{{/if}}`;
    addPlaceholderToContent(placeholder);
    setOpenPlaceholderAlternativePopup(false);
  };

  const addUsesPlaceholder = (searchWord, options) => {
    let placeholder = "";
    if (!wordClicked) {
      const indexOfSearchWord = templateDetails.content
        ?.toLowerCase()
        .indexOf(searchWord.toLowerCase());
      placeholder = `{{${searchWord}}}`;
      if (indexOfSearchWord && indexOfSearchWord !== -1) {
        if (
          templateDetails.content?.toLowerCase().indexOf(searchWord + "_") !==
          -1
        ) {
          let occurenceNumber = 2;
          while (
            templateDetails.content
              ?.toLowerCase()
              .indexOf(`${searchWord}_${occurenceNumber}`) !== -1
          ) {
            occurenceNumber++;
          }
          placeholder = `{{${searchWord}_${occurenceNumber}}}`;
        } else {
          placeholder = "{{" + searchWord + "_2}}";
        }
      }
      addPlaceholderToContent(placeholder);
    } else if (wordClicked) {
      placeholder = wordClicked;
      setWordClicked(null);
    }

    const configCopy = templateDetails.config;
    configCopy.placeholders = {
      ...templateDetails.config.placeholders,
      [placeholder.slice(2, placeholder.length - 2)]: options,
    };
    setOldImageUseOptions(null);
    setOldSalutationsOptions(null);
  };

  const addPlaceholderToContent = (placeholder) => {
    if (curPosition || curPosition === 0) {
      let textBeforeCursorPosition = templateDetails.content.substring(
        0,
        curPosition
      );
      let textAfterCursorPosition = templateDetails.content.substring(
        curPosition,
        templateDetails.content.length
      );
      setTemplateDetails({
        ...templateDetails,
        content: `${textBeforeCursorPosition}${placeholder}${textAfterCursorPosition}`,
      });
    } else {
      setTemplateDetails({
        ...templateDetails,
        content: `${templateDetails.content}${placeholder}`,
      });
    }
  };

  // return the word under cursor postion or undefined if no word was found
  const getSelectedWord = (text, position) => {
    if (!text || text.trim().length === 0) return undefined;
    var word = "";
    if (text[position]) {
      // search forward
      for (var i = position; i < 5000; i++) {
        let char = text[i];
        if ((char && char.trim().length === 0) || char === "{") {
          break;
        } else if (char && char.trim().length !== 0) {
          word += char;
          if (char === "}" && text[i + 1] === "}") {
            word += "}";
            break;
          }
        } else break;
      }

      // search backward
      for (var j = position - 1; j < 5000; j--) {
        let char = text[j];
        if ((char && char.trim().length === 0) || char === "}") {
          break;
        } else if (char && char.trim().length !== 0) {
          word = char + word;
          if (char === "{" && text[j - 1] === "{") {
            word = "{" + word;
            break;
          }
        } else break;
      }
    }

    return word === "" ? undefined : word;
  };

  // handle click event in textarea
  const handleSelectedWordClicked = (e) => {
    var text = e.target.value;
    var cursorposition = e.target.selectionStart;

    var selectedWord = getSelectedWord(text, cursorposition);
    if (selectedWord?.includes("image_uses") && selectedWord?.length < 20) {
      setOldImageUseOptions(
        templateDetails.config?.placeholders?.[
          selectedWord.slice(2, selectedWord.length - 2)
        ]
      );
      window.alert(selectedWord);
      setOpenImageUsesPopup(true);
      setWordClicked(selectedWord);
    } else if (
      selectedWord?.includes("salutation") &&
      selectedWord?.length < 20
    ) {
      setOldSalutationsOptions(
        templateDetails.config?.placeholders?.[
          selectedWord.slice(2, selectedWord.length - 2)
        ]
      );
      setOpenSalutationsPopup(true);
      setWordClicked(selectedWord);
    }
  };

  const handleClosePlaceholderPopup = () => {
    setOpenSalutationsPopup(false);
    setOpenImageUsesPopup(false);
    setWordClicked(null);
  };

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}{" "}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      {templateReducer.addTemplate || selectedTemplate ? (
        <>
          <div className={styles.confirm_btn_container}>
            <div className={styles.template_name_container}>
              <TextfieldInput
                label="Template Name"
                placeholder="Template Name"
                onChange={handleInputChange}
                name="name"
                value={templateDetails.name}
                required
                inputProps={{
                  tabindex: 1,
                }}
                error={btnPressed && isNull(templateDetails.name)}
              />
            </div>

            {templateDetails.type ===
              emailConstants.TEMPLATE_TYPE_SCHEDULED && (
              <div className={styles.template_days_container}>
                <TextfieldInput
                  id="outlined-number"
                  label="Days after intial Email"
                  placeholder="Days after intial Email"
                  type="number"
                  inputProps={{
                    tabindex: 2,
                  }}
                  name="schedule_num_days"
                  onChange={handleInputChange}
                  value={templateDetails.schedule_num_days}
                  required
                  error={
                    btnPressed && isNull(templateDetails.schedule_num_days)
                  }
                />
              </div>
            )}

            {templateDetails.type === emailConstants.TEMPLATE_TYPE_TRIGGERED &&
              templateDetails.trigger_event &&
              templateDetails.trigger_event ===
                emailConstants.TRIGGER_EVENT_DISOUNT_END && (
                <div className={styles.template_days_container}>
                  <p>To be sent 1 day before discount ends</p>
                </div>
              )}

            <div className={styles.template_action_container}>
              <Button
                tabindex={5}
                className={styles.confirm_btn}
                onClick={handleSubmitTemplateDetails}
              >
                Save
              </Button>
              <Button
                className={` ${styles.btn_cancel} btn_cancel ${
                  templateReducer.add_template ? "btn_disabled" : ""
                } `}
                onClick={handleDeleteTemplate}
                tabindex={6}
                disabled={templateReducer.add_template}
              >
                Delete
              </Button>
            </div>
          </div>

          <div ref={temlateContainerRef} className={styles.template_container}>
            <div className={styles.template_col}>
              <TextfieldInput
                id="subject_input"
                placeholder="Email Subject"
                label="Email Subject"
                onChange={handleInputChange}
                name="subject"
                value={templateDetails.subject}
                required
                inputProps={{
                  tabindex: 3,
                  style: { width: "100%" },
                }}
                sx={{ input: { width: "100%" } }}
                error={btnPressed && isNull(templateDetails.subject)}
                onClick={(e) => handleSubjectPlaceholder(e)}
              />

              {/*<RichTextEditor
                key={selectedTemplate.id}
                reference={editor}
                defaultValue={content}
                setContentCallback={setContent}
                onClickCallback={handleSaveCursorPosition}
                showAttachmentButton={false}
              />*/}
              <TextfieldInput
                placeholder="Enter Text"
                label="Enter Text"
                multiline
                inputProps={{
                  tabindex: 4,
                }}
                rows={25}
                onChange={handleInputChange}
                name="content"
                value={templateDetails.content}
                required
                error={btnPressed && isNull(templateDetails.content)}
                onClick={(e) => {
                  handleSaveCursorPosition(e);
                  handleSelectedWordClicked(e);
                }}
              />
            </div>

            <div className={styles.placeholder_col}>
              <h1 className={styles.placeholder_group}>Placeholders</h1>
              <div
                className={styles.placeholder_info_container}
                ref={measuredRef}
                id="placeholder_container_id"
              >
                <p className={styles.placeholder_group}>
                  Website owner details
                </p>
                {templateReducer.placeholders &&
                  templateReducer.placeholders.placeholders &&
                  templateReducer.placeholders.placeholders.map(
                    (placeholder) => {
                      if (placeholder.category === "opponent details") {
                        return (
                          <>
                            {" "}
                            <div
                              className={
                                styles.placeholder_item +
                                " " +
                                ((!placeholder.subject_placeholder &&
                                  subjectClicked) ||
                                disabledPlaceholders
                                  ? styles.placeholder_disabled
                                  : styles.placeholder_enabled)
                              }
                              key={placeholder.name}
                            >
                              <Tooltip
                                title={
                                  <div
                                    className={styles.tooltip_title}
                                    dangerouslySetInnerHTML={{
                                      __html: placeholder.description,
                                    }}
                                  ></div>
                                }
                              >
                                <p
                                  onClick={(e) =>
                                    handleAddPlaceholder(
                                      placeholder.name,
                                      placeholder.subject_placeholder
                                    )
                                  }
                                >
                                  {placeholder.name}
                                </p>
                              </Tooltip>
                            </div>
                          </>
                        );
                      } else return <></>;
                    }
                  )}
                <p className={styles.placeholder_group}>
                  License offer details
                </p>
                {templateReducer.placeholders &&
                  templateReducer.placeholders.placeholders &&
                  templateReducer.placeholders.placeholders.map(
                    (placeholder, i) => {
                      if (placeholder.category === "infringement details") {
                        return (
                          <>
                            {" "}
                            <div
                              className={
                                styles.placeholder_item +
                                " " +
                                ((!placeholder.subject_placeholder &&
                                  subjectClicked) ||
                                disabledPlaceholders
                                  ? styles.placeholder_disabled
                                  : styles.placeholder_enabled)
                              }
                              key={placeholder.name}
                            >
                              <Tooltip
                                title={
                                  <div
                                    className={styles.tooltip_title}
                                    dangerouslySetInnerHTML={{
                                      __html: placeholder.description,
                                    }}
                                  ></div>
                                }
                              >
                                <p
                                  onClick={(e) =>
                                    handleAddPlaceholder(
                                      placeholder.name,
                                      placeholder.subject_placeholder
                                    )
                                  }
                                >
                                  {placeholder.name}
                                </p>
                              </Tooltip>
                            </div>
                          </>
                        );
                      } else return <></>;
                    }
                  )}
                <p className={styles.placeholder_group}>case access details </p>
                {templateReducer.placeholders &&
                  templateReducer.placeholders.placeholders &&
                  templateReducer.placeholders.placeholders.map(
                    (placeholder) => {
                      if (placeholder.category === "case access details") {
                        return (
                          <>
                            {" "}
                            <div
                              className={
                                styles.placeholder_item +
                                " " +
                                ((!placeholder.subject_placeholder &&
                                  subjectClicked) ||
                                disabledPlaceholders
                                  ? styles.placeholder_disabled
                                  : styles.placeholder_enabled)
                              }
                            >
                              <Tooltip
                                title={
                                  <div
                                    className={styles.tooltip_title}
                                    dangerouslySetInnerHTML={{
                                      __html: placeholder.description,
                                    }}
                                  ></div>
                                }
                              >
                                <p
                                  onClick={(e) =>
                                    handleAddPlaceholder(
                                      placeholder.name,
                                      placeholder.subject_placeholder
                                    )
                                  }
                                >
                                  {placeholder.name}
                                </p>
                              </Tooltip>
                            </div>
                          </>
                        );
                      } else return <></>;
                    }
                  )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.template_no_data}>No Template Selected</div>
      )}
      <AreYouSurePopup
        open={openAreYouSurePopup}
        close={() => {
          setDeleteOrSubmit(null);
          setOpenAreYouSurePopup(false);
        }}
        onClick={deleteTemplate}
      >
        Are you sure you want to delete this template?
      </AreYouSurePopup>

      <ChangeEmailTemplatePopup
        open={openChangeEmailTemplatePopup}
        close={() => {
          setDeleteOrSubmit(null);
          setOpenChangeEmailTemplatePopup(false);
        }}
        onYesClick={() => {
          deleteOrSubmit === 0
            ? deleteTemplate(true)
            : handleSubmitTemplateDetails(true);
        }}
        onNoClick={() => {
          deleteOrSubmit === 0
            ? deleteTemplate(false)
            : handleSubmitTemplateDetails(false);
        }}
      >
        <h3>
          Do you want to apply these changes on active cases using this flow?
        </h3>
        <p>
          Changes made to scheduled days may lead to needed manual intervention
          on some cases
        </p>
      </ChangeEmailTemplatePopup>
      <InfoPopup
        title="Affected cases"
        open={openInfoPopup}
        close={() => setOpenInfoPopup(false)}
      >
        <p>
          {templateReducer.affectedCases?.length} case(s) affected.
          {templateReducer.affectedCases?.some(
            (affectedCase) =>
              affectedCase.status === caseConstants.CASE_ACTIVITY_STATE_FAILED
          ) && (
            <>
              <br />
              These cases failed and need manual interaction:
              <ol>
                {templateReducer.affectedCases.map((affectedCase) => (
                  <li>{affectedCase.case_id}</li>
                ))}{" "}
              </ol>
            </>
          )}
        </p>
      </InfoPopup>
      <SalutationsPlaceholderPopup
        open={openSalutationsPopup}
        close={handleClosePlaceholderPopup}
        onAdd={addUsesPlaceholder}
        salutation={oldSalutationsOptions}
      />
      <AreYouSurePopup
        title={"Greeting"}
        open={openPlaceholderAlternativePopup}
        close={() => {
          setOpenPlaceholderAlternativePopup(false);
        }}
        cancelBtn={"Cancel"}
        confirmBtn={"OK"}
        onClick={writePlaceholderAlternativeToEditor}
      >
        <div>
          <p>If name is available:</p>
        </div>
        <div>
          <TextfieldInput
            label="Text with name"
            value={placeHolderPresent}
            onChange={(e) => {
              setPlaceHolderPresent(e.target.value);
            }}
            required
          />
        </div>
        <div>
          <p>If name is not available:</p>
        </div>
        <div>
          <TextfieldInput
            label="Alternative text"
            value={placeHolderNotPresent}
            onChange={(e) => {
              setPlaceHolderNotPresent(e.target.value);
            }}
            required
          />
        </div>
      </AreYouSurePopup>
      <ImageUsesPlaceholderPopup
        open={openImageUsesPopup}
        close={handleClosePlaceholderPopup}
        onAdd={addUsesPlaceholder}
        oldImageUseOptions={oldImageUseOptions}
      />
    </div>
  );
}

export default EmailTemplate;
