export const emailConstants = {
  EMAIL_FLOW_GET_REQUEST: "EMAIL_FLOW_GET_REQUEST",
  EMAIL_FLOW_GET_SUCCESS: "EMAIL_FLOW_GET_SUCCESS",
  EMAIL_FLOW_GET_FAILURE: "EMAIL_FLOW_GET_FAILURE",

  EMAIL_FLOW_POST_REQUEST: "EMAIL_FLOW_POST_REQUEST",
  EMAIL_FLOW_POST_SUCCESS: "EMAIL_FLOW_POST_SUCCESS",
  EMAIL_FLOW_POST_FAILURE: "EMAIL_FLOW_POST_FAILURE",

  EMAIL_FLOW_UPDATE_REQUEST: "EMAIL_FLOW_UPDATE_REQUEST",
  EMAIL_FLOW_UPDATE_SUCCESS: "EMAIL_FLOW_UPDATE_SUCCESS",
  EMAIL_FLOW_UPDATE_FAILURE: "EMAIL_FLOW_UPDATE_FAILURE",

  EMAIL_FLOW_DELETE_REQUEST: "EMAIL_FLOW_DELETE_REQUEST",
  EMAIL_FLOW_DELETE_SUCCESS: "EMAIL_FLOW_DELETE_SUCCESS",
  EMAIL_FLOW_DELETE_FAILURE: "EMAIL_FLOW_DELETE_FAILURE",

  SELECT_EMAIL_FLOW: "EMAIL_FLOW_SELECT",
  ADD_FLOW_CONFIG: "ADD_FLOW_CONFIG",

  // clear status
  EMAIL_FLOW_CLEAR_STATUS_MESSAGE: "EMAIL_FLOW_CLEAR_STATUS_MESSAGE",

  TEMPLATE_TYPE_TRIGGERED: "TRIGGERED",
  TEMPLATE_TYPE_SCHEDULED: "SCHEDULED",
  TEMPLATE_TYPE_CUSTOM: "CUSTOM",

  TRIGGER_EVENT_INITIAL: "INITIAL",
  TRIGGER_EVENT_DISOUNT_END: "DISCOUNT_END",
  TRIGGER_EVENT_LICENSE_VALIDATED: "LICENSE_VALIDATED",
  TRIGGER_EVENT_LICENSE_REJECTED: "LICENSE_REJECTED",
  TRIGGER_EVENT_PAID: "PAID",

  AFTER_INITIAL: "AFTER_INITIAL",
  BEFORE_DISCOUNT_END: "BEFORE_DISCOUNT_END",

  SCHEDULE_TYPE_AFTER_INITIAL: "AFTER_INITIAL",
  SCHEDULE_TYPE_BEFORE_DISCOUNT_END: "BEFORE_DISCOUNT_END",
};
